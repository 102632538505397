
























































import {
  Component, Vue, Prop,
} from 'vue-property-decorator';

import moment from 'moment';

import QrcodeVue from 'qrcode.vue';

import { PaymentSuccess } from '../../interfaces/IPayment';

import PaymentService from '@/services/Payment/PaymentService';
import { PAYMENT_STATUS } from '../../constants';

const TIME_FOR_NEW_PAYMENT_REQUEST_IN_SECONDS = 10;
const TIME_INTERVAL_ONDE_SECONDS_IN_MILLISECONDS = 1000;

@Component({
  components: {
    QrcodeVue,
  },
})
export default class PixCode extends Vue {
  @Prop({ required: true }) code!: string;
  @Prop({ required: true }) paymentStatusProp!: string;

  private secondsCounter = 300;
  private seconds = 0;
  private timeoutGetPaymentId = 0;
  private clock = '';
  private paymentStatus = this.paymentStatusProp;

  private qrCodeSize = 300;

  private PaymentService = new PaymentService();

  mounted() {
    this.timeCounter();
  }

  beforeDestroy() {
    clearTimeout(this.timeoutGetPaymentId);
  }

  async timeCounter() {
    if (this.secondsCounter === 0) {
      this.setRedirect();
      return;
    }

    this.secondsCounter -= 1;
    this.seconds += 1;

    if (this.seconds === TIME_FOR_NEW_PAYMENT_REQUEST_IN_SECONDS) {
      this.getPayment();
    }

    this.timeoutGetPaymentId = setTimeout(this.timeCounter, TIME_INTERVAL_ONDE_SECONDS_IN_MILLISECONDS);

    const formattedTime = moment.utc(this.secondsCounter * 1000).format('HH:mm:ss');

    this.clock = formattedTime;
  }

  async getPayment() {
    try {
      const { idPurchase } = this.$route.params;

      const [payment]: PaymentSuccess[] = await this.PaymentService.getPayment({
        paymentID: Number(idPurchase),
      });

      if (payment) {
        this.paymentStatus = payment?.payment.status_external;

        this.checkPaymentStatus();
      }

    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao retornar os dados do pagamento.',
        status: 'error',
      });
      console.error({ error });
    }
  }

  checkPaymentStatus() {
    if (!this.paymentStatus || this.paymentStatus === PAYMENT_STATUS.PENDING) {
      this.seconds = 0;
      return;
    }

    if (this.paymentStatus === PAYMENT_STATUS.PAID) {
      this.emitStatusPaid();
      return;
    }

    if (this.paymentStatus === PAYMENT_STATUS.CANCELED) {
      this.emitStatusCanceled();
    }
  }

  setRedirect() {
    if (this.paymentStatus === PAYMENT_STATUS.PENDING) {
      this.emitStatusPending();
      return;
    }

    if (this.paymentStatus === PAYMENT_STATUS.PAID) {
      this.emitStatusPaid();
      return;
    }

    this.emitStatusCanceled();
  }

  copyText(): void {
    const input = this.$refs.inputCode as HTMLInputElement;
    input.select();
    document.execCommand('copy');

    this.$store.dispatch('Toast/setToast', {
      status: 'info',
      text: 'Link copiado para a área de transferência!',
    });
  }

  emitStatusPending() {
    this.$emit('pending');
  }

  emitStatusPaid() {
    this.$emit('paid');
  }

  emitStatusCanceled() {
    this.$emit('canceled');
  }
}
